import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { checkCommentsFetching } from "selectors/comments";
import { checkIsBusinessUser } from "selectors/user";
import { checkSelectedBusinessHasBusinessUsers, getSelectedBusinessUsersData } from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import CommentsActions from "actions/CommentsActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const { MENTION_TRIGGER_CHAR } = Constants;

const { COMMENT_TYPES: { COMMENT, NOTE } } = DataConstants;

const messageDraft = { [COMMENT]: "", [NOTE]: "" };

const TRIGGER_CHAR_PATTERN = new RegExp(`${MENTION_TRIGGER_CHAR}(\\S*)$`);

const AddCommentField = ({ onlyNotes, ...props }) => {
  const [commentType, setCommentType] = useState(onlyNotes ? NOTE : COMMENT);

  const [innerValue, setComment] = useState(messageDraft[commentType]);

  const innerRef = useRef();

  const {
    id,
    type,
    portal,
    popupMode,
    commentsLoaded,
    compact,
    disabled,
    className,
    placeholder,
    autoHeight = true,
    alignUserListRight,
    inputRef = innerRef,
    value: comment = innerValue,
    onChange = setComment,
    onFocus,
    onBlur,
    onAskClient,
    ...restProps
  } = props;

  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const usersData = useSelector(getSelectedBusinessUsersData);

  const businessUser = useSelector(checkIsBusinessUser);

  const fetchingComments = useSelector(checkCommentsFetching);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const [opened, setOpened] = useState(false);

  const [focused, setFocused] = useState(false);

  const [commentSent, setCommentSent] = useState(false);

  const askClientAvailable = !businessOrganization || selectedBusinessHasBusinessUsers;

  const outerButtons = onAskClient && !businessUser && askClientAvailable;

  const usersEmails = useMemo(() => {
    return usersData.map(({ email, fullName }) => email + (fullName === email ? "" : ` (${fullName})`));
  }, [usersData]);

  const filteredUsersEmails = useMemo(() => {
    const triggerMatch = comment.match(TRIGGER_CHAR_PATTERN);

    if (!triggerMatch) return [];

    const [, searchString] = triggerMatch;

    if (!searchString) return usersEmails;

    return usersEmails.filter((item) => {
      const itemValue = item.trim().toLowerCase();

      return itemValue === searchString || itemValue.includes(searchString);
    });
  }, [comment, usersEmails]);

  const sendComment = useCallback(() => {
    const message = comment.trim();

    if (!message) return;

    const mentioned = [...new Set(message.match(new RegExp(`${MENTION_TRIGGER_CHAR}\\S*`, "g")) || [])]
      .map((mention) => {
        return (usersData.find(({ email }) => email === mention.slice(1)) || {}).sub;
      })
      .filter(Boolean);

    messageDraft[commentType] = "";

    dispatch(CommentsActions.addNewComment(commentType, message, mentioned, null, id, type));

    onChange("");
    setFocused(false);
    setCommentSent(true);
  }, [comment, commentType, dispatch, id, type, onChange, usersData]);

  const handleSendMessageClick = useCallback(() => {
    sendComment();
  }, [sendComment]);

  const handleAutoCompleteClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleCommentTextChange = useCallback((text) => {
    if (text.match(TRIGGER_CHAR_PATTERN)) {
      setOpened(true);
    }
    onChange(text);
  }, [onChange]);

  const handleAutoComplete = useCallback((text) => {
    onChange((prev) => {
      return prev && `${prev.slice(0, prev.lastIndexOf(MENTION_TRIGGER_CHAR) + 1) + text} `;
    });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, onChange]);

  const handleTypeSelectorClick = useCallback((event) => {
    const { type: nextType } = event.target.dataset;

    setCommentType(nextType);
    setComment(messageDraft[nextType]);
  }, []);

  const handleAskClient = useCallback(() => {
    sendComment();
    onAskClient();
  }, [sendComment, onAskClient]);

  const handleInputFocus = useCallback((event) => {
    if (onFocus) onFocus(event);
    setFocused(true);
  }, [onFocus]);

  const handleInputBlur = useCallback((event) => {
    if (onBlur) onBlur(event);
    setFocused(false);
  }, [onBlur]);

  const handleKeyDown = useCallback((event) => {
    if (event.key !== "Enter" || event.shiftKey || event.ctrlKey) return;

    event.preventDefault();

    sendComment();
  }, [sendComment]);

  useEffect(() => {
    if ((popupMode && commentsLoaded) || commentSent) {
      if (!disabled && !fetchingComments && inputRef.current && document.activeElement !== inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [popupMode, commentsLoaded, commentSent, disabled, fetchingComments, inputRef]);

  return (
    <div
      className={classNames(
        Css.addCommentField,
        outerButtons && Css.outerButtons,
        commentType === NOTE && Css.typeNote,
        className
      )}>
      <div className={Css.container}>
        {!businessUser && !compact && (
          <div className={classNames(Css.typeSelector, focused && Css.focused)} onClick={handleTypeSelectorClick}>
            {!onlyNotes && (
              <div
                className={classNames(Css.type, commentType === COMMENT && Css.active)}
                data-type={COMMENT}>{uiTexts.reply}</div>
            )}
            <div
              className={classNames(Css.type, commentType === NOTE && Css.active)}
              data-type={NOTE}>{uiTexts.note}</div>
          </div>
        )}
        <div className={Css.relative}>
          <AutoCompleteInput
            {...restProps}
            autoHeight={autoHeight}
            selectOnFocus={false}
            alignListRight={alignUserListRight}
            portal={portal}
            multiline={!compact}
            opened={opened}
            disabled={disabled || fetchingComments}
            useCaret={false}
            filteredItems={filteredUsersEmails}
            inputRef={inputRef}
            className={classNames(Css.autoComplete, !businessUser && !compact && Css.withTabs)}
            placeholder={placeholder || (compact
              ? uiTexts.enterComment
              : Utils.replaceTextVars(
                businessUser
                  ? messages.commentPlaceholder
                  : (commentType === COMMENT ? messages.commentReplyPlaceholder : messages.commentNotePlaceholder),
                { triggerChar: MENTION_TRIGGER_CHAR }
              ))}
            value={comment}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            onClick={handleAutoCompleteClick}
            onChange={handleCommentTextChange}
            onAutoComplete={handleAutoComplete} />
          <div className={classNames(Css.actions, compact && Css.compact)}>
            {(!outerButtons || commentType !== COMMENT) && (
              <Button
                outline
                light={!outerButtons}
                title={uiTexts.send}
                className={classNames(Css.sendButton, !outerButtons && Css.iconOnly)}
                disabled={disabled || !comment.trim() || fetchingComments}
                onClick={handleSendMessageClick}>
                {outerButtons ? uiTexts.send : (
                  <>
                    <Icons.PaperPlaneTilt weight="fill" />
                    <Icons.PaperPlaneTilt weight="bold" />
                  </>
                )}
              </Button>
            )}
            {outerButtons && commentType === COMMENT && (
              <Button
                primary
                disabled={!comment.trim() || fetchingComments}
                onClick={handleAskClient}>
                {uiTexts.sendAndAskClient}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddCommentField);
