import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Tooltip } from "nlib/ui";
import {
  checkSelectedBusinessHasBusinessUsers,
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData,
  getSelectedBusinessIntegrationServiceData
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getLockedTransactions } from "selectors/transactions";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useState } from "react";
import Transactions from "utils/Transactions";
import TransactionsActions from "actions/TransactionsActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";
import useTransactionUtils from "hooks/useTransactionUtils";

const { AUDIT_SUBSECTIONS_ROUTES, TRANSACTION_FIELDS_TO_EDIT } = Constants;

const {
  STATUSES: { NEED_REACTION, EXPORTED, TO_RECONCILE },
  GPT_FINE_TUNE_STATUSES: { SUCCEEDED }
} = DataConstants;

const Actions = (props) => {
  const {
    auditRoute,
    selected,
    selectable,
    readyToProcess,
    readyToApprove,
    transaction,
    transaction: {
      id: transactionId,
      aiProcessing,
      status,
      timestamp,
      extraData = {}
    },
    onManualModeSet,
    transactionState,
    transactionState: { manualMode },
    refetchTableData,
    onSelectedChange
  } = props;

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const selectedBusinessIntegrationServiceData = useSelector(getSelectedBusinessIntegrationServiceData);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const closedBookDate = selectedBusinessBookCloseDate
    && moment(timestamp).isSameOrBefore(moment(selectedBusinessBookCloseDate));

  const { uiTexts, messages } = useSelector(getTextsData);

  const { extraData: { integrationServiceConnected, lastGptAiFineTuneStatus } = {} } = useSelector(getSelectedBusinessData);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const lockedTransactions = useSelector(getLockedTransactions);

  const locked = !!lockedTransactions[transactionId];

  const { onTransactionApplyChanges } = useTransactionUtils();

  const needReactionStatus = status === NEED_REACTION;

  const exportedStatus = status === EXPORTED;

  const allowAskClient = selectable && (!businessOrganization || selectedBusinessHasBusinessUsers);

  const allCategorizedRoute = auditRoute === AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED;

  const hasChanges = Transactions.hasChanges(transactionState, transaction);

  const [tooltipOpened, setTooltipOpened] = useState(false);

  const markAsRevised = useCallback(async() => {
    const dataToEdit = Utils.getProps(transactionState, TRANSACTION_FIELDS_TO_EDIT);

    const transactionHasChange = !Utils.deepEqual(dataToEdit, Utils.getProps(transaction, TRANSACTION_FIELDS_TO_EDIT));

    if (transactionHasChange && closedBookDate) {
      const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

      showCommonModal({
        text: Utils.replaceTextVars(messages.closedBookWarning, {
          date: closedBookTimeText,
          service: selectedBusinessIntegrationServiceData.label
        }),
        headerText: uiTexts.warning
      });

      return false;
    }

    let result = !xeroBusiness && transactionHasChange
      ? await dispatch(TransactionsActions.editTransaction(transactionId, dataToEdit, false, true))
      : true;

    if (result) {
      result = await dispatch(AuditActions.markAsRevised({ transactionsIds: [transactionId], currentRoute: auditRoute }));
    }

    return result;
  }, [
    transactionState,
    closedBookDate,
    transaction,
    xeroBusiness,
    dispatch,
    transactionId,
    selectedBusinessBookCloseDate,
    showCommonModal,
    messages.closedBookWarning,
    selectedBusinessIntegrationServiceData.label,
    uiTexts.warning,
    auditRoute
  ]);

  const handleSaveButtonClick = useCallback(async() => {
    const result = await onTransactionApplyChanges(transaction, transactionState);

    if (result) refetchTableData();
  }, [transaction, transactionState, refetchTableData, onTransactionApplyChanges]);

  const handleAskClientClick = useCallback(async() => {
    if (needReactionStatus && !rpaMode) {
      await dispatch(TransactionsActions.bulkTransactionsUpdate({
        ids: [transactionId],
        data: { status: TO_RECONCILE }
      }));
      refetchTableData();
    } else {
      onSelectedChange(transactionId, !selected);
    }
  }, [rpaMode, needReactionStatus, dispatch, transactionId, onSelectedChange, selected, refetchTableData]);

  const handleMarkAsRevisedClick = useCallback(async() => {
    const result = await markAsRevised();

    if (result) refetchTableData();
  }, [markAsRevised, refetchTableData]);

  const handleGoToClick = useCallback(async() => {
    const { xeroSourceUrl, quickBooksSourceUrl } = extraData;

    window.open(xeroBusiness ? xeroSourceUrl : quickBooksSourceUrl);
    if (xeroBusiness) {
      const result = await markAsRevised();

      if (result) refetchTableData();
    }
  }, [extraData, xeroBusiness, markAsRevised, refetchTableData]);

  const handleUncategorizedGoToClick = useCallback(() => {
    const { xeroSourceUrl, quickBooksSourceUrl } = extraData;

    window.open(xeroBusiness ? xeroSourceUrl : quickBooksSourceUrl);
  }, [extraData, xeroBusiness]);

  const handleMouseEnter = useCallback(() => {
    setTooltipOpened(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTooltipOpened(false);
  }, []);

  if (auditRoute) {
    const disabledApply = (allCategorizedRoute && !hasChanges) || locked;

    const dangerState = hasChanges && closedBookDate;

    return (
      <div className={Css.actions}>
        {(xeroBusiness || auditRoute === AUDIT_SUBSECTIONS_ROUTES.DUPLICATES) && (
          <div
            className={Css.buttonWrap}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <Button
              outline secondary
              icon={Icons.ArrowSquareOut}
              onClick={handleGoToClick}>
              {uiTexts.open}
            </Button>
            <Tooltip
              opened={tooltipOpened}
              place="left"
              contentClassName={Css.tooltip}>
              <Icons.Warning />
              <span>{xeroBusiness ? messages.auditTransactionsXeroRedirect : messages.auditDuplicateQboTransaction}</span>
            </Tooltip>
          </div>
        )}
        {(!allCategorizedRoute || !xeroBusiness) && (
          <Button
            outline
            className={classNames(Css.applyButton)}
            success={!dangerState && (!allCategorizedRoute || hasChanges)}
            danger={dangerState}
            disabled={disabledApply}
            icon={dangerState ? Icons.Warning : (allCategorizedRoute ? Icons.Check : Icons.ThumbsUp)}
            onClick={handleMarkAsRevisedClick}>
            {(allCategorizedRoute || hasChanges) ? uiTexts.applyChanges : uiTexts.markAsCorrect}
          </Button>
        )}
      </div>
    );
  }

  if (rpaMode && !exportedStatus) {
    const rpaDisableApprove = aiProcessing || ((manualMode || !needReactionStatus) ? !readyToApprove : !readyToProcess);

    return (
      <div className={Css.actions}>
        {allowAskClient && (
          <Button
            outline
            data-id={transactionId}
            warning={!selected}
            danger={selected}
            icon={selected ? Icons.X : Icons.Question}
            onClick={handleAskClientClick}>
            {selected ? uiTexts.doNotAsk : uiTexts.askClient}
          </Button>
        )}
        {needReactionStatus && !aiProcessing && !manualMode && (
          <Button
            outline
            icon={Icons.PencilSimple}
            onClick={onManualModeSet}>
            {manualMode ? uiTexts.enterComment : uiTexts.enterManually}
          </Button>
        )}
        <Button
          outline
          disabled={rpaDisableApprove}
          primary={!rpaDisableApprove && needReactionStatus}
          success={!rpaDisableApprove && (!needReactionStatus || manualMode)}
          danger={!rpaDisableApprove && closedBookDate}
          className={classNames(aiProcessing && Css.processing)}
          icon={(!rpaDisableApprove && closedBookDate && Icons.Warning)
            || (aiProcessing ? Icons.Spinner : (needReactionStatus && !manualMode ? Icons.Robot : Icons.Check))}
          onClick={handleSaveButtonClick}>
          <span>
            {aiProcessing
              ? uiTexts.processing
              : (needReactionStatus && !manualMode && (lastGptAiFineTuneStatus === SUCCEEDED)
                ? uiTexts.aiCategorize : uiTexts.approve)}
          </span>
        </Button>
      </div>
    );
  }

  const disableApprove = status === EXPORTED
    ? (!quickBooksBusiness || !integrationServiceConnected || !Transactions.hasChanges(transactionState, transaction))
    : !readyToApprove;

  return (
    <div className={Css.actions}>
      {allowAskClient && (
        <Button
          outline
          data-id={transactionId}
          warning={!selected && !needReactionStatus}
          danger={selected || needReactionStatus}
          icon={(selected || needReactionStatus) ? Icons.X : Icons.Question}
          onClick={handleAskClientClick}>
          {(selected || needReactionStatus) ? uiTexts.doNotAsk : uiTexts.askClient}
        </Button>
      )}
      {xeroBusiness && status === EXPORTED && (
        <div
          className={Css.buttonWrap}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <Button
            outline secondary
            icon={Icons.ArrowSquareOut}
            onClick={handleUncategorizedGoToClick}>
            {uiTexts.open}
          </Button>
          <Tooltip
            opened={tooltipOpened}
            place="top"
            contentClassName={Css.tooltip}>
            <Icons.Warning />
            <span>{messages.transactionsXeroRedirect}</span>
          </Tooltip>
        </div>
      )}
      <Button
        outline
        disabled={disableApprove}
        title={exportedStatus ? uiTexts.applyChanges : uiTexts.approve}
        icon={!disableApprove && closedBookDate ? Icons.Warning : Icons.PaperPlaneTilt}
        success={!closedBookDate && !disableApprove}
        danger={!disableApprove && closedBookDate}
        onClick={handleSaveButtonClick}>
        {exportedStatus ? uiTexts.applyChanges : uiTexts.approve}
      </Button>
    </div>
  );
};

export default React.memo(Actions);
